<template>
  <div class="table">
    <div class="head">
      <!-- <el-input
        placeholder="请输入内容"
        v-model="input"
        clearable
        style="width: 200px"
      >
      </el-input> -->
      <el-input
        placeholder="请输入字典类型"
        v-model="input"
        class="input-with-select"
        style="width: 250px"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="sousuo()"
        ></el-button>
      </el-input>
      <el-button
        class="filter-item"
        style="margin-left: 10px"
        type="primary"
        icon="el-icon-edit"
        @click="clickadd()"
      >
        添加
      </el-button>
    </div>
    <div class="tablebox">
      <el-table :data="tableData.slice((currentPage - 1) * pageSize, currentPage * pageSize)" style="width: 100%" border>
        <el-table-column prop="dictLabel" label="字典标签"> </el-table-column>
        <el-table-column prop="dictType" label="字典类型"> </el-table-column>
        <el-table-column prop="dictValue" label="字典值"> </el-table-column>
        <el-table-column label="操作" width="250px">
          <template slot-scope="scope">
            <el-button
              type="primary"
              size="mini"
              @click="handleUpdate(scope.row)"
            >
              编辑
            </el-button>
            <el-button
              size="mini"
              type="danger"
              @click="handleDelete(scope.row)"
            >
              删除
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="block">
      <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage"
        :page-sizes="[5, 10, 20, 40]"
        :page-size="10"
        layout="sizes, prev, pager, next"
        :total="tableData.length"
      >
      </el-pagination>
    </div>
    <!-- 编辑弹窗 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible"
      width="50%"
      :show-close="false"
    >
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="80px"
        :model="formLabelAlign"
      >
        <el-form-item label="字典标签">
          <el-input v-model="formLabelAlign.dictLabel"></el-input>
        </el-form-item>
        <el-form-item label="字典类型">
          <el-select v-model="value" filterable placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.dictType"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="字典值">
          <el-input v-model="formLabelAlign.dictValue"></el-input>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="config()">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 新建弹窗 -->
    <el-dialog
      title="编辑"
      :visible.sync="dialogVisible2"
      width="50%"
      :show-close="false"
    >
      <!-- 表单 -->
      <el-form
        :label-position="labelPosition"
        label-width="80px"
        :model="formLabelAlign"
      >
        <el-form-item label="字典标签">
          <el-input v-model="formLabelAlign.dictLabel"></el-input>
        </el-form-item>
        <el-form-item label="字典类型">
          <el-select v-model="value" filterable placeholder="请选择">
            <el-option
              v-for="item in options"
              :key="item.id"
              :label="item.dictType"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="字典值">
          <el-input v-model="formLabelAlign.dictValue"></el-input>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible2 = false">取 消</el-button>
        <el-button type="primary" @click="config2()">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import {
  GetsysDictType,
  GetsysDictTypelistData,
  PostsysDictTypesaveData,
  PutsysDictTypeupdateData,
  DeletesysDictTypedeleteData,
} from "@/request/api";
export default {
  name: "HtglTable",

  data() {
    return {
      input: "", //搜索内容
      tableData: [],
      dialogVisible: false, //编辑弹出框判定
      dialogVisible2: false, //新建弹出框判定
      labelPosition: "right",
      formLabelAlign: {
        //弹窗数据
        dictLabel: "",
        dictType: "",
        dictValue: "",
      },
      currentPage: 1, //分页
      pageSize: 10,
      id: "",
      options: [], //类型列表
      value: "", //选中值
      valuetxt: "", //选中值
    };
  },

  methods: {
    // 搜索
    sousuo() {
      GetsysDictTypelistData({
        dictSort: 0,
        dictType: this.input,
        pageNum: 1,
        pageSize: 10,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
        }
      });
    },
    // 编辑
    handleUpdate(v) {
      this.id = v.id;
      this.formLabelAlign.dictLabel = v.dictLabel;
      this.options.map((item, index) => {
        if (v.dictType == item.dictType) {
          this.value = item.id;
        }
      });
      this.formLabelAlign.dictValue = v.dictValue;
      this.dialogVisible = true;
    },
    // 编辑确定
    config() {
      this.options.map((item, index) => {
        if (this.value == item.id) {
          this.valuetxt = item.dictType;
        }
      });
      PutsysDictTypeupdateData({
        createTime: "",
        deleteFlag: "",
        dictLabel: this.formLabelAlign.dictLabel,
        dictSort: 0,
        dictType: this.valuetxt,
        dictValue: this.formLabelAlign.dictValue,
        id: this.id,
        updateTime: "",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.dialogVisible = false;
          this.seelist();
        }
      });
    },
    // 删除
    handleDelete(v) {
      // console.log(v);
      this.$confirm("此操作将永久删除该数据, 是否继续?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          DeletesysDictTypedeleteData({ id: v.id }).then((res) => {
            // console.log(res);
            if (res.code == 200) {
              this.seelist();
              this.$message({
                type: "success",
                message: "删除成功!",
              });
            }
          });
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    //添加数据
    clickadd() {
      this.formLabelAlign.dictLabel = "";
      this.formLabelAlign.dictType = "";
      this.formLabelAlign.dictValue = "";
      this.value = "";
      this.dialogVisible2 = true;
    },
    // 添加数据确定
    config2() {
      var name = "";
      this.options.map((item, index) => {
        if (this.value == item.id) {
          name = item.dictType;
        }
      });
      PostsysDictTypesaveData({
        createTime: "",
        deleteFlag: "",
        dictLabel: this.formLabelAlign.dictLabel,
        dictSort: 0,
        dictType: name,
        dictValue: this.formLabelAlign.dictValue,
        id: "",
        updateTime: "",
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.seelist();
          this.dialogVisible2 = false;
        }
      });
    },
    //分页
    handleSizeChange(val) {
      // console.log(val);
      this.pageSize = val;
    },
    handleCurrentChange(val) {
      // console.log(val);
      this.currentPage = val;
    },
    // 标签类型列表
    seelist2() {
      GetsysDictType({
        pageNum: 1,
        pageSize: 10,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.options = res.data.list;
        }
      });
    },
    // 标签列表
    seelist() {
      GetsysDictTypelistData({
        dictSort: 0,
        dictType: "",
        pageNum: 1,
        pageSize: 100000,
      }).then((res) => {
        // console.log(res);
        if (res.code == 200) {
          this.tableData = res.data.list;
        }
      });
    },
  },
  mounted() {
    this.seelist();
    this.seelist2();
  },
};
</script>

<style lang="scss" scoped>
.table {
  width: 100%;
  .head {
    margin-bottom: 15px;
    display: flex;
  }
  .tablebox {
    width: 100%;
    padding: 10px;
    background-color: #fff;
  }
  .block {
    text-align: center;
    margin-top: 15px;
  }
}
</style>